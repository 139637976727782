import React from 'react';
import {graphql} from 'gatsby';
import Main from '@components/layout/main';
import {ArticleSection, EventObject} from '../utils/types';
import ArticleTextCard from '@components/article/article-text-card';
import ArticleImageCard from '@components/article/article-image-card';
import TextShadow from '@components/elements/text-shadow';
import SlimHeader from '@components/layout/slim-header';
import Meta from '@components/layout/meta';

export default function BlogEntry({data}) {
    const event: EventObject = data.strapiEvent;

    return (
        <>
            <Meta
                title='Bündnis #noIAA'
                description={`Klima vor Profit - Mobilitätswende jetzt! ${event.date}, ${event.time}, ${event.location} - ${event.name}`}
                type='article'
                path={`/event/${event.identifier}`}
            />
            <SlimHeader backTo='/#events' />
            <Main slimHeader>
                <h1 className='relative max-w-3xl mx-auto mt-8 text-3xl text-center md:mt-4 lg:mt-0 rubik'>
                    <TextShadow text={event.name} />
                </h1>
                <div className='pt-3 pb-5 text-xl crimson font-weight-500'>
                    {event.date}, {event.time}, {event.location}
                </div>
                <article className='w-full space-y-4 centering-col'>
                    {event.invitation.map(
                        (node: ArticleSection, index: number) => {
                            switch (node.strapi_component) {
                                case 'article-section.text':
                                    return (
                                        <ArticleTextCard
                                            key={index}
                                            node={node}
                                        />
                                    );
                                case 'article-section.image':
                                    return (
                                        <ArticleImageCard
                                            key={index}
                                            node={node}
                                        />
                                    );
                                default:
                                    return <React.Fragment />;
                            }
                        },
                    )}
                </article>
            </Main>
        </>
    );
}

export const query = graphql`
    query($identifier: String!) {
        strapiEvent(identifier: {eq: $identifier}) {
            name
            date
            time
            location
            identifier
            invitation {
                strapi_component
                image {
                    url
                    width
                    height
                }
                copyright
                subtitle
                text
            }
        }
    }
`;
